<template>
  <v-row
    no-gutters
    class="indiscipline-and-health"
  >
    <v-col
      cols="12"
      class="pa-0"
    >
      <edudash-select
        v-model="schoolSelected"
        :items="getTeacherSchools"
        placeholder="Nome da escola"
      />
    </v-col>
    <v-col
      cols="6"
      class="pr-1"
    >
      <edudash-select
        v-model="schoolClassSelected"
        :items="schoolClassesList"
        placeholder="Turma"
      />
    </v-col>
    <v-col
      cols="6"
      class="pl-1"
    >
      <edudash-select
        v-model="studentSelected"
        :items="studentsList"
        placeholder="Aluno"
      />
    </v-col>
    <v-col
      cols="12"
    >
      <edudash-graph
        v-if="hasGraphData"
        :graph-data="fillGraphData"
        :datasets="fillGraphDatasets"
      />
      <div
        v-if="!showGraph"
        class="placeholder"
      >
        {{ $t('views.indiscipline_and_health.select_school') }}
      </div>
      <div
        v-if="noGraphDataAvaliable"
        class="placeholder"
      >
        {{ $t('views.indiscipline_and_health.no_data_avaliable') }}
      </div>
      <div
        v-if="hasGraphData"
        class="historical"
        @click="goToOccurrenceHistory"
      >
        {{ $t('views.indiscipline_and_health.see_historical') }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EdudashSelect from '@/components/forms/EdudashSelect.vue';
import EdudashGraph from '@/components/graphs/EdudashGraph.vue';

export default {
  name: 'IndisciplineAndHealth',
  components: {
    EdudashSelect,
    EdudashGraph,
  },
  data() {
    return {
      schoolSelected: {},
      schoolClassSelected: {},
      studentSelected: {},
      schoolClasses: [],
      students: [],
      graphData: {},
      graphDatasets: [],
    };
  },
  computed: {
    ...mapGetters([
      'getTeacherSchools',
      'getSchoolClass',
      'getIndisciplinAndHeatlhAlerts',
    ]),
    schoolClassesList() {
      return this.schoolClasses.filter((schoolClass) => schoolClass.active);
    },
    studentsList() {
      return this.students;
    },
    fillGraphData() {
      return this.graphData;
    },
    fillGraphDatasets() {
      return this.graphDatasets;
    },
    hasGraphData() {
      return !this.isEmpty(this.schoolSelected) && this.dataIsAvaliable;
    },
    noGraphDataAvaliable() {
      return !this.isEmpty(this.schoolSelected) && !this.dataIsAvaliable;
    },
    showGraph() {
      return !this.isEmpty(this.schoolSelected);
    },
    dataIsAvaliable() {
      if (!this.isEmpty(this.graphData)) {
        const categories = Object.values(this.graphData);
        categories.pop();
        return categories.some((category) => !this.arrayIsEmpty(category));
      }
      return true;
    },
  },
  watch: {
    schoolSelected(value) {
      if (value.school_classes[0].name !== 'Todos') {
        value.school_classes.unshift({ name: 'Todos' });
      }
      this.schoolClasses = value.school_classes;
      this.findAlertsInformations(value.id, '', '');
    },
    schoolClassSelected(value) {
      if (value.name !== 'Todos') {
        this.findSchoolClass({ schoolClassId: value.id });
        this.findAlertsInformations(this.schoolSelected.id, value.id, '');
      } else {
        this.students = [{ name: 'Todos' }];
        this.findAlertsInformations(this.schoolSelected.id, '', '');
      }
    },
    studentSelected(value) {
      if (value.name !== 'Todos') {
        this.findAlertsInformations(this.schoolSelected.id, this.schoolClassSelected.id, value.id);
      } else {
        this.allOptionSelected(value.id);
      }
    },
    getSchoolClass(value) {
      value.students.unshift({ name: 'Todos' });
      this.students = value.students;
    },
    getIndisciplinAndHeatlhAlerts(value) {
      this.graphData = value.graph_data;
      this.graphDatasets = value.graph_datasets;
    },
  },
  mounted() {
    this.findTeacherSchools();
    this.changeTab(this.$route.name);
  },
  methods: {
    ...mapActions([
      'findTeacherSchools',
      'findSchoolClass',
      'findIndisciplineAndHealthAlerts',
      'findStudentOccurrences',
      'changeTab',
    ]),
    findAlertsInformations(school, schoolClass, student) {
      this.findIndisciplineAndHealthAlerts({
        school_id: school,
        school_class_id: schoolClass,
        student_id: student,
      });
    },
    allOptionSelected(studentId) {
      if (this.schoolClassSelected === 'Todos') {
        this.findAlertsInformations(this.schoolSelected.id, '', '');
      } else {
        this.findAlertsInformations(this.schoolSelected.id, studentId, '');
      }
    },
    goToOccurrenceHistory() {
      this.$router.push({
        name: 'historicalOccurrences',
        params: {
          backPath: 'alertsIndisciplineAndHealth',
          socioemotional: false,
          studentId: this.studentSelected.id,
        },
        query: {
          by_school: this.schoolSelected.id,
          by_school_class: this.schoolClassSelected.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
  .indiscipline-and-health {
    padding: 12px 16px;

    .v-select {
      height: 34px;
    }

    .placeholder {
      @include font-open-sans-bold;
      font-size: $font-size-extra-small;
      text-align: center;
      margin: 20px 0 26px 0;
      color: $text-color-primary;
    }

    .historical {
      @include font-open-sans-bold;
      font-size: $font-size-extra-small;
      color: $secondary-button-color;
      margin: 20px 0 40px 0;
      text-align: center;
    }
  }
</style>
