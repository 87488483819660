<template>
  <div class="edudash-graph">
    <line-chart
      :chart-data="dataCollections"
      :options="graphOptions"
      class="chart-box"
      @canvas="handleCanvas"
      @chart="handleChart"
    />
  </div>
</template>
<script>
import LineChart from '@/components/graphs/LineChart';

export default {
  name: 'EdudashGraph',
  components: {
    LineChart,
  },
  props: {
    graphData: {
      type: Object,
      default: () => {},
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataCollection: {},
      options: {},
      canvas: {},
      chart: {},
    };
  },
  computed: {
    dataCollections() {
      return this.dataCollection;
    },
    graphOptions() {
      return this.options;
    },
  },
  watch: {
    graphData() {
      this.fillCollection();
      this.fillOptions();
    },
    datasets() {
      this.fillCollection();
      this.fillOptions();
    },
  },
  mounted() {
    this.fillCollection();
    this.fillOptions();
  },
  methods: {
    handleChart(object) {
      this.chart = object;
    },
    handleCanvas(object) {
      this.canvas = object;
    },
    fillCollection() {
      this.dataCollection = {
        labels: this.graphData.months,
        datasets: this.datasets.map(
          (dataset) => ({
            label: dataset.name,
            data: this.graphData[dataset.name],
            borderColor: dataset.border_color,
            backgroundColor: dataset.border_color,
            pointRadius: 1,
            borderWidth: 2,
            fill: false,
            borderDashOffset: 8,
          }),
        ),
      };
    },
    fillOptions() {
      this.options = {
        backgroundColor: this.$vuetify.theme.themes.light.bg_color,
        responsive: true,
        maintainAspectRatio: true,
        type: 'line',
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 28,
            boxHeight: 16,
            fontFamily: 'OpenSans-SemiBold',
            fontColor: this.$vuetify.theme.themes.light.text_secondary_color,
            fontSize: 10,
          },
        },
        layout: {
          padding: 1,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                borderDash: [4, 4],
              },
              ticks: {
                beginAtZero: true,
                min: 0,
                suggestedMin: 0,
                suggestedMax: 5,
                fontSize: 12,
                fontFamily: 'OpenSans-SemiBold',
                fontColor: this.$vuetify.theme.themes.light.secondary,
                stepSize: 1,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              ticks: {
                fontSize: 10,
                fontFamily: 'OpenSans-SemiBold',
                fontColor: this.$vuetify.theme.themes.light.secondary,
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss">
.edudash-graph {
  background: $border-color;
}
</style>
